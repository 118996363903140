import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import 'react-modern-drawer/dist/index.css';

import { UserContext } from './contexts/userContext';

import './index.scss';

import App from './App';

const renderApp = (activeSession = {}) => {
  ReactDOM.render(
    <BrowserRouter>
      <UserContext.Provider
        value={{
          loggedIn: activeSession.ok,
        }}
      >
        <App />
      </UserContext.Provider>
    </BrowserRouter>,
    document.getElementById('root')
  );
};

(async () => {
  try {
    renderApp();
  } catch (e) {
    console.error('Startup error.', e.toString());
  }
})();
