import React, { } from 'react';
import { Routes, Route } from 'react-router-dom';

import Dashboard from './components/Dashboard';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/cookie-policy' element={<Dashboard showCookiesText />} />
      <Route path='*' element={<Dashboard />} />
    </Routes>
  );
}

export default App;
