import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSpring, animated } from 'react-spring';
import ReactModal from 'react-modal';

import {
  CheckIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';

import InfoModal from '../InfoModal';

import LogoGif from '../../images/logo_eug/logo.gif';
import LogoGifBlack from '../../images/logo_eug/logo_black.gif';
import LogoPng from '../../images/logo_eug/logo.png';
import LogoPngBlack from '../../images/logo_eug/logo_black.png';
import LoadingGif from '../../images/loaders/loading.gif';

import WelcomeImg1 from '../../images/welcome/benvenuto.png';
import WelcomeImg2 from '../../images/welcome/clicca-qui.png';

import Cookies from '../Cookies';

import styles from './Dashboard.module.scss';
import YouTube from 'react-youtube';

ReactModal.setAppElement(document.getElementById('root'));

let player = null;

const useTypeWriter = ({ delay, text, infinite, startDelay, showLoader, savedForm }) => {
  const [animationEnabled, setAnimationEnabled] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (!showLoader && !savedForm) {
      setTimeout(() => {
        setAnimationEnabled(true);
      }, startDelay || 0);

      if (currentIndex <= text.length) {
        if (animationEnabled) {
          timeout = setTimeout(() => {
            if (currentIndex < text.length && text[currentIndex]) {
              setCurrentText(prevText => prevText + text[currentIndex]);
              setCurrentIndex(prevIndex => prevIndex + 1);
            }
          }, delay);
        }
      } else if (infinite) { // ADD THIS CHECK
        setCurrentIndex(0);
        setCurrentText('');
      }
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text, startDelay, animationEnabled, showLoader, savedForm]);

  return currentText;
}

const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
const r = randomBetween(0, 255);
const g = randomBetween(0, 255);
const b = randomBetween(0, 255);

const RANDOM_COLOR = `rgb(${r}, ${g}, ${b})`;

let SAVED_FORM = '';
let COOKIE_ACK = '';

try {
  SAVED_FORM = localStorage.getItem('eug-ui-form-sent');
  COOKIE_ACK = localStorage.getItem('eug-ui-cookie-ack');
} catch (e) {
  console.log('Error reading localStorage', e.message);
}

let numberOfVideoLoaded = 0;

const Dashboard = ({
  showCookiesText
}) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [fobia, setFobia] = useState('');
  const [desideri, setDesideri] = useState('');
  const [fobiaPlaceholder, setFobiaPlaceholder] = useState('');
  const [desideriPlaceholder, setDesideriPlaceholder] = useState('');
  const [videoMuted, setMuted] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [gifLoaded, setGifLoaded] = useState(false);
  const [gifLoaded3, setGifLoaded3] = useState(false);
  const [gifLoaded4, setGifLoaded4] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [savedForm, setSavedForm] = useState(false);
  const [cinemaMode, setCinemaMode] = useState(false);
  const [lightMode, setLightMode] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [loadNextVideo, setLoadNextVideo] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [showSpotify, setShowSpotify] = useState(false);
  const [logoPulse, setLogoPulse] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [welcomePopup, setWelcomePopup] = useState(false);
  const textAreaRef1 = useRef();
  const textAreaRef2 = useRef();

  const isDesktop = window.innerWidth > 1024;

  const [animationStyles, animationApi] = useSpring(() => ({
    from: {
      opacity: 0,
      y: isDesktop ? '100px' : '100px',
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const [textAnimationStyles, textAnimationApi] = useSpring(() => ({
    from: {
      opacity: 0,
      y: '100px',
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const [cookiesStyles, cookiesApi] = useSpring(() => ({
    from: {
      opacity: 0,
      y: '100px',
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const [logoStyles, logoApi] = useSpring(() => ({
    from: {
      x: '-100px',
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const [sendButtonStyles, sendButtonApi] = useSpring(() => ({
    from: {
      y: '48px',
      opacity: 0
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const [textAnimationStyles2, textAnimationApi2] = useSpring(() => ({
    from: {
      opacity: 0,
      y: '100px',
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const sendButtonDisabled = !fobia.length || !desideri.length;

  const requestVideo = async (next = '', loading) => {
    const response = await fetch(`/api/video/${next}`);
    const json = await response.json();

    setVideoUrl(json.data.videoId);

    if (loading) {
      setLoadNextVideo(false);
    }
  }

  const sendFormData = async () => {
    setFormSent(false);

    try {
      const response = await fetch('/api/video/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fobia,
          desideri
        })
      });

      await response.json();

      setFormSent(true);
      setLoading(false);

      setTimeout(() => {
        try {
          const formSent = localStorage.getItem('eug-ui-form-sent') || 0;

          localStorage.setItem('eug-ui-form-sent', parseInt(formSent, 10) + 1);

          if (parseInt(formSent, 10) + 1 >= 10) {
            setSavedForm(true);
          } else {
            setFobia('');
            setDesideri('');
            setFormSent(false);
          }
        } catch (e) {
          console.log('Error setting localStorage', e.message);
        }

        setShowInfoModal('thank-you');
      }, 1000);
    } catch (e) {
      console.error('Error sending form', e.message);
      setLoading(false);
    }
  }

  const mountComponents = ({ loadVideo = false, noAnimation = false } = {}) => {
    setShowLoader(false);

    if (numberOfVideoLoaded === 1) {
      animationApi.start({
        from: {
          y: isDesktop ? '100px' : '-100%',
          x: isDesktop && !savedForm ? '50%' : '0',
        },
        to: async (next, cancel) => ([
          await next({
            y: '0',
            opacity: 1,
          }),
          savedForm ? await cancel() : await next({
            y: isDesktop ? '0%' : '0',
            x: savedForm ? '0' : '0%'
          })
        ]),
      });
    }


    if (loadVideo) {
      if (numberOfVideoLoaded === 1) {
        setTimeout(() => {
          textAnimationApi.start({
            to: {
              y: '0',
              opacity: 1,
            },
          });

          setTimeout(() => {
            textAnimationApi2.start({
              to: {
                y: '0',
                opacity: 1,
              },
            });

            setWelcomePopup(true);
          }, 1000);
        }, 1200);
      }

      try {
        setTimeout(async () => {
          const response = await fetch('/api/video/');
          const json = await response.json();

          if (json.data.seconds || json.data.seconds === 0) {
            if (player) {
              player.setPlaybackQuality('hd1080');
              player.seekTo(json.data.seconds);

              const playerState = player.getPlayerState();

              if (playerState === 2) {
                player.playVideo();
              }

              // 107 is "NOTTE"
              const nightTimer = 107 - json.data.seconds;

              if (nightTimer >= 0) {
                setTimeout(() => {
                  setLightMode(false);
                }, nightTimer * 1000);
              }
            }
          }
        }, 0);
      } catch (e) {
        console.warn('Error getting seconds', e.message);
      }
    }

    window.scrollTo(0, 0);

    logoApi.start({
      to: {
        x: '0',
      },
    });

    if (noAnimation) {
      setGifLoaded(true);
      setLogoLoaded(true);
      setGifLoaded3(true);
      setGifLoaded4(true);

      return;
    }

    setTimeout(() => {
      setGifLoaded(true);
      setLogoLoaded(true);
    }, 1200);
    setTimeout(() => {
      setGifLoaded3(true);
    }, 2800);
    setTimeout(() => {
      setGifLoaded4(true);
    }, 3600);
  };

  const handleVideoLoaded = (iframe) => {
    player = iframe;
    numberOfVideoLoaded += 1;

    setVideoLoaded(true);

    if (!showLoader) {
      mountComponents({ loadVideo: true });
    }
  }

  const handleVideoEnd = () => {
    setLoadNextVideo(true);
    requestVideo('', true);
  }

  const onSendFormData = () => {
    if (sendButtonDisabled) {
      return;
    }

    setLoading(true);

    setTimeout(() => {
      sendFormData();
    }, 1000);
  }

  const onTextChange = ({ target: { value } }) => {
    setFobia(value);
  }

  const onDesideriTextChange = ({ target: { value } }) => {
    setDesideri(value);
  }


  const unmuteVideo = () => {
    setMuted(false);

    if (player && player.isMuted()) {
      player.unMute();
    }

    startStars({ videoPlaying: true, lightMode });
  }

  const muteVideo = () => {
    setMuted(true);

    if (player && player.mute) {
      player.mute();
    }

    startStars({ lightMode });
  }

  const fullscreenVideo = () => {
    unmuteVideo();
    setCinemaMode(true);
    startStars({ videoPlaying: true, lightMode });
  };

  const exitFullscreenVideo = () => {
    setCinemaMode(false);
  };

  const toggleTheme = () => {
    setLightMode(!lightMode);

    startStars({ lightMode: !lightMode });
  };

  const onInfoClick = () => {
    if (showCookiesText) {
      location.href = '/';
    } else {
      window.dataLayer.push({ event: 'info' });

      setShowInfoModal(true);
    }
  };

  const onInfoModalClose = () => {
    setShowInfoModal(false);
  };

  const startStars = async ({ videoPlaying = false, lightMode: lightModeUpdate = false } = {}) => {
    try {
      await window.tsParticles.load('tsparticles', {
        'background': {
          'color': lightModeUpdate ? '#FFFFFF' : '#000000'
        },
        'particles': {
          'number': {
            'value': videoPlaying ? 210 : 69,
            'density': {
              'enable': true,
              'value_area': 473.51100518494565
            }
          },
          'color': {
            'value': RANDOM_COLOR
          },
          'shape': {
            'type': 'circle',
            'stroke': {
              'width': 0,
              'color': '#000000'
            },
            'polygon': {
              'nb_sides': 5
            },
            'image': {
              'src': 'img/github.svg',
              'width': 100,
              'height': 100
            }
          },
          'opacity': {
            'value': 1,
            'random': true,
            'anim': {
              'enable': false,
              'speed': videoPlaying ? 2 : 1,
              'opacity_min': 0,
              'sync': false
            }
          },
          'size': {
            'value': 1.5,
            'random': true,
            'anim': {
              'enable': false,
              'speed': 75.4716981132076,
              'size_min': 0.3,
              'sync': false
            }
          },
          'line_linked': {
            'enable': false,
            'distance': 150,
            'color': '#ffffff',
            'opacity': 0.4,
            'width': 1
          },
          'move': {
            'enable': true,
            'speed': videoPlaying ? 0.6 : 0.12,
            'direction': 'none',
            'random': true,
            'straight': false,
            'out_mode': 'bounce',
            'bounce': false,
            'attract': {
              'enable': false,
              'rotateX': 600,
              'rotateY': 600
            }
          }
        },
        'interactivity': {
          'detect_on': 'canvas',
          'events': {
            'onhover': {
              'enable': false,
              'mode': 'bubble'
            },
            'onclick': {
              'enable': false,
              'mode': 'repulse'
            },
            'resize': true
          },
          'modes': {
            'grab': {
              'distance': 400,
              'line_linked': {
                'opacity': 1
              }
            },
            'bubble': {
              'distance': 250,
              'size': 0,
              'duration': 2,
              'opacity': 0,
              'speed': 3
            },
            'repulse': {
              'distance': 400,
              'duration': 0.4
            },
            'push': {
              'particles_nb': 4
            },
            'remove': {
              'particles_nb': 2
            }
          }
        },
        'retina_detect': true,
        preset: 'stars',
      });
    } catch (e) {
      console.warn('Could not load particles', e.message);
    }
  };

  useEffect(() => {
    requestVideo();

    setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    setInterval(() => {
      setShowSpotify(prev => !prev);
    }, 12000);

    setInterval(() => {
      setLogoPulse(true);

      setTimeout(() => {
        setLogoPulse(false);
      }, 1000);
    }, 15000);
  }, []);

  const onReady = (event) => {
    console.log('On Ready', event.target);

    handleVideoLoaded(event.target);
  };

  const onEnd = () => {
    console.log('On End');
    handleVideoEnd();
  };

  const onStateChange = () => {
    console.log('On State Change');
  };

  useEffect(() => {
    startStars({ lightMode });
  }, [lightMode]);

  const typeWriter1 = useTypeWriter({ showLoader, savedForm, delay: 72, text: 'Aggiungi le tue paure' });
  const typeWriter2 = useTypeWriter({ showLoader, savedForm, startDelay: 1200, delay: 72, text: 'Aggiungi i tuoi sogni' });

  useEffect(() => {
    if (parseInt(SAVED_FORM) >= 10) {
      setSavedForm(true);
    }
  }, [SAVED_FORM]);

  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef1.current && !showLoader) {
        textAreaRef1.current.placeholder = typeWriter1;
      }
    }, 1000);
  }, [textAreaRef1, typeWriter1, showLoader, isDesktop]);

  useEffect(() => {
    if (!showLoader) {
      setTimeout(() => {
        if (textAreaRef1.current) {
          if (isDesktop) {
            textAreaRef1.current.focus();
          }
          setFobiaPlaceholder('Aggiungi le tue paure');
        }
      }, 4000);
    }
  }, [textAreaRef1.current, showLoader, isDesktop]);

  useEffect(() => {
    if (!showLoader) {
      setTimeout(() => {
        if (textAreaRef2.current) {
          setDesideriPlaceholder('Aggiungi i tuoi sogni');
        }
      }, 5000);
    }
  }, [textAreaRef2.current, showLoader]);

  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef2.current && !showLoader) {
        textAreaRef2.current.placeholder = typeWriter2;
      }
    }, 1000);
  }, [textAreaRef2, typeWriter2, showLoader]);

  useEffect(() => {
    if (!showLoader && !COOKIE_ACK) {
      setShowCookies(true);

      setTimeout(() => {
        cookiesApi.start({
          to: async (next) => ([
            await next({
              y: '0',
              opacity: 0.420,
            }),
          ]),
        });
      }, 6000);
    }
  }, [showLoader]);

  useEffect(() => {
    if (!showLoader && videoLoaded) {
      mountComponents({ loadVideo: true });
    }
  }, [showLoader, videoLoaded]);

  useEffect(() => {
    if (!showLoader && showCookiesText) {
      mountComponents({ noAnimation: true });
    }
  }, [showLoader, showCookiesText]);

  useEffect(() => {
    sendButtonApi.start({
      to: {
        opacity: fobia.length && desideri.length ? 1 : 0,
        y: fobia.length && desideri.length ? '0' : '48px'
      }
    });
  }, [fobia.length, desideri.length]);

  const mainClass = clsx({
    [styles.main]: true,
    [styles['main--lightMode']]: lightMode,
  })

  const sendButtonClass = clsx({
    btn: true,
    'btn-ghost': true,
    [styles.sendButton]: true,
    [styles['sendButton--mobile']]: !isDesktop,
    [styles['sendButton--disabled']]: sendButtonDisabled,
  });

  const fullscreenButtonClass = clsx({
    [styles.button]: true,
    [styles['button--fullscreen']]: true,
    [styles['button--fullscreen--loaded']]: gifLoaded3,
  });

  const exitFullscreenButtonClass = clsx({
    [styles.button]: true,
    [styles['button--exitFullscreen']]: true,
    [styles['button--exitFullscreen--loaded']]: cinemaMode,
  });

  const instagramButtonClass = clsx({
    [styles.button]: true,
    [styles['button--instagram']]: true,
    [styles['button--instagram--loaded']]: gifLoaded4,
  });

  const spotifyButtonClass = clsx({
    [styles.button]: true,
    [styles['button--spotify']]: true,
    [styles['button--spotify--loaded']]: gifLoaded4,
  });

  const unmuteButtonClass = clsx({
    [styles.button]: true,
    [styles['button--unmute']]: true,
    [styles['button--unmute--loaded']]: gifLoaded,
    [styles['button--unmute--cinemaMode']]: cinemaMode,
  });

  const muteButtonClass = clsx({
    [styles.button]: true,
    [styles['button--mute']]: true,
    [styles['button--mute--loaded']]: gifLoaded,
    [styles['button--mute--cinemaMode']]: cinemaMode,
  });

  const infoButtonClass = clsx({
    [styles.button]: true,
    [styles['button--info']]: true,
    [styles['button--info--loaded']]: gifLoaded4,
  });

  const themeSunButtonClass = clsx({
    [styles.button]: true,
    [styles['button--themeSun']]: true,
    [styles['button--themeSun--loaded']]: gifLoaded4,
  });

  const themeMoonButtonClass = clsx({
    [styles.button]: true,
    [styles['button--themeMoon']]: true,
    [styles['button--themeMoon--loaded']]: gifLoaded4,
  });

  const contentClass = clsx({
    [styles.content]: true,
    [styles['content--collapsed']]: true,
    [styles['content--savedForm']]: savedForm,
    [styles['content--cinemaMode']]: cinemaMode,
    [styles['content--cookies']]: showCookiesText,
  });

  const visibleImageClass = clsx({
    [styles.logo]: true,
    [styles['logo--show']]: true,
  });

  const logoContainerClass = clsx({
    [styles.logoContainer]: true,
    [styles['logoContainer--pulse']]: logoPulse,
  });

  const textareaClass = clsx({
    [styles.textarea]: true,
    [styles['textarea--filled']]: fobia.length && desideri.length,
  });

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      disablekb: 1,
      controls: 0,
      enablejsapi: 1,
      mute: 1,
      loop: 1,
      playsinline: 1,
      origin: 'https://youtube.com'
    },
  };

  return (
    <div className={mainClass}>
      <ReactModal
        isOpen={welcomePopup}
        overlayClassName="ReactModal__Overlay"
        className="ReactModal__Content"
        bodyOpenClassName="ReactModal__Body--open"
        htmlOpenClassName="ReactModal__Html--open"
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        role="dialog"
      >
        <div className={styles.welcome}>
          <img className={styles.welcomeImg1} alt="welcome-1" src={WelcomeImg1} />
          <a href="https://youtu.be/d1lJuMzvtec?si=TNH_LqJ3Bawy9FCA" target='_blank' rel="noreferrer">
            <img className={styles.welcomeImg2} alt="welcome-2" src={WelcomeImg2} />
          </a>
          <button onClick={() => setWelcomePopup(false)} />
        </div>
      </ReactModal>
      {showCookies && (
        <animated.div className={styles.cookies} style={cookiesStyles}>
          <span>
            Utilizziamo i cookies per il corretto funzionamento e analisi del sito; nessun salvataggio di informazioni personali. Per maggiori Informazioni <a href="/cookie-policy" target='_blank'>&nbsp;<u>clicca qui</u>.</a>
          </span>
          <button onClick={() => {
            setShowCookies(false);

            try {
              localStorage.setItem('eug-ui-cookie-ack', 'true');
            } catch (e) {
              console.warn('Could not access localStorage');
            }
          }}>
            <XMarkIcon className="h-5 w-5" />
          </button>
        </animated.div>
      )}
      <animated.div className={logoContainerClass} style={logoStyles} onClick={() => {
        if (showCookiesText) {
          location.href = '/';
        } else {
          setShowInfoModal('logo-info');
          window.dataLayer.push({ event: 'eugenio' });
        }
      }}>
        <img className={`${logoLoaded ? styles.logo : visibleImageClass}`} src={lightMode ? LogoGifBlack : LogoGif} alt="info-gif" />
        <img className={`${logoLoaded ? visibleImageClass : styles.logo}`} src={lightMode ? LogoPngBlack : LogoPng} alt="info-png" />
      </animated.div>
      {showInfoModal && (
        <InfoModal
          onClose={onInfoModalClose}
          lightMode={lightMode}
          showThankYou={showInfoModal === 'thank-you'}
          showLogoInfo={showInfoModal === 'logo-info'}
        />
      )}
      {showLoader && (
        <div className={styles.placeholder}>
          <img src={LoadingGif} alt="loading" />
        </div>
      )}
      {lightMode ? (
        <div
          role='button'
          onClick={toggleTheme}
          className={themeSunButtonClass}
        />
      ) : (
        <div
          role='button'
          onClick={toggleTheme}
          className={themeMoonButtonClass}
        />
      )}
      <div
        role='button'
        onClick={onInfoClick}
        className={infoButtonClass}
      />
      {showSpotify ? (
        <a
          href="https://open.spotify.com/track/77LDtxb5hTAeW7BBawplSn?si=ee6bbfdf77ac496c"
          target='_blank'
          className={spotifyButtonClass} rel="noreferrer"
          onClick={() => {
            window.dataLayer.push({ event: 'spotify' });
          }}
        />
      ) : (
        <a
          href="https://www.instagram.com/eugenioinviadigioia/"
          target='_blank'
          className={instagramButtonClass} rel="noreferrer"
          onClick={() => {
            window.dataLayer.push({ event: 'instagram' });
          }}
        />
      )}
      {cinemaMode && isDesktop && (
        <Fragment>
          <div
            role='button'
            onClick={exitFullscreenVideo}
            className={exitFullscreenButtonClass}
          />
          {videoMuted ? (
            <div
              role='button'
              onClick={unmuteVideo}
              className={unmuteButtonClass}
            />
          ) : (
            <div
              role='button'
              onClick={muteVideo}
              className={muteButtonClass}
            />
          )}
        </Fragment>
      )}
      <div className={contentClass}>
        {showCookiesText ? (
          <Cookies />
        ) : (
          <Fragment>
            <animated.div
              className={styles.videoContainer}
              style={animationStyles}
            >
              {(!cinemaMode || (!isDesktop && cinemaMode)) && (
                <div className={styles.buttonsContainer}>
                  {videoMuted ? (
                    <div
                      role='button'
                      onClick={unmuteVideo}
                      className={unmuteButtonClass}
                    />
                  ) : (
                    <div
                      role='button'
                      onClick={muteVideo}
                      className={muteButtonClass}
                    />
                  )}
                  {cinemaMode ? (
                    <div
                      role='button'
                      onClick={exitFullscreenVideo}
                      className={exitFullscreenButtonClass}
                    />
                  ) : (
                    <div
                      role='button'
                      onClick={fullscreenVideo}
                      className={fullscreenButtonClass}
                    />
                  )}
                </div>
              )}
              {videoUrl && !loadNextVideo ? (
                <YouTube
                  videoId={videoUrl}
                  id={videoUrl}
                  iframeClassName={styles.video}
                  opts={opts}
                  onReady={onReady}
                  onEnd={onEnd}
                  onStateChange={onStateChange}
                />
              ) : (
                <span className="loading loading-spinner" />
              )}
            </animated.div>
            {!showLoader && !savedForm && !cinemaMode && (
              <div className={styles.mobileForm}>
                <animated.div className={styles.mobileFormPrompt} style={textAnimationStyles}>
                  <textarea
                    className={textareaClass}
                    onChange={onTextChange}
                    value={fobia}
                    maxLength={78}
                    ref={textAreaRef1}
                    placeholder={fobiaPlaceholder}
                  />
                </animated.div>
                <animated.div className={styles.mobileFormPrompt} style={textAnimationStyles2}>
                  <textarea
                    className={textareaClass}
                    onChange={onDesideriTextChange}
                    value={desideri}
                    maxLength={78}
                    ref={textAreaRef2}
                    placeholder={desideriPlaceholder}
                  />
                </animated.div>
                <animated.button
                  onClick={onSendFormData}
                  className={sendButtonClass}
                  style={sendButtonStyles}
                >
                  {loading ? (
                    <span className="loading loading-spinner" />
                  ) : (
                    <Fragment>
                      {formSent ? (
                        <Fragment>
                          <CheckIcon className="h-6 w-6" />
                        </Fragment>
                      ) : (
                        <Fragment>
                          Invia
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </animated.button>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  showCookiesText: PropTypes.bool
}

export default Dashboard;
