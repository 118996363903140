import React from 'react';

import styles from './Cookies.module.scss';

const Cookies = () => {
  return (
    <div className={styles.main} role="main">
      <div className="row row-main">
        <div className="large-12 col">
          <div className="col-inner">
            <h1>Cookie Policy</h1>
            <p><strong>del sito </strong><a
              href="https://www.stormi.eugenioinviadigioia.it"><strong>www.stormi.eugenioinviadigioia.it</strong></a><strong>
                (“Sito”).</strong></p>
            <p><strong>Cosa sono i cookies?</strong></p>
            <p>Un cookie è un file di testo di piccole dimensioni che viene inviato dal <em>sito web</em> al
              <em>browser</em> e salvato nel tuo computer quando si visitano determinate pagine su internet.</p>
            <p>I cookies possono svolgere diverse funzioni, come ad esempio permetterti di navigare fra le varie
              pagine in modo efficiente, ricordando le tue preferenze, e in generale possono migliorare la
              permanenza dell&apos;utente nel sito web, inoltre consentono al proprietario del sito web di avere
              informazioni utili per fini statistici o pubblicitari e per personalizzare la tua esperienza di
              navigazione.</p>
            <p><strong>Quali cookies utilizziamo e a quale scopo?</strong></p>
            <p>Di seguito vene riportata una descrizione di come 2050 S.r.l., titolare del trattamento (“Titolare”),
              utilizza i cookies e strumenti simili.</p>
            <p><strong>Cookie di navigazione</strong></p>
            <p>Sono cookies tecnici fondamentali per permetterti di muoverti all&apos;interno del Sito e per usare le sue
              funzionalità, quali ad esempio accedere alle aree riservate del sito internet, riconoscere
              (ricordando per i futuri accessi) la lingua ed il paese di provenienza. Senza questi cookies non
              possono essere forniti i servizi richiesti, come ad esempio il carrello acquisti, il <em>log-in</em>
              e la gestione degli ordini. Questi cookies sono strettamente necessari al corretto funzionamento del
              Sito e la loro disattivazione potrebbe pregiudicarne la fruibilità da parte degli utenti.</p>
            <p><strong>Cookie funzionali</strong></p>
            <p>Questi cookies possono avere durata di sessione o persistenti e permettono al sito di riconoscerti
              agli accessi successivi, in modo che tu non debba, ad ogni collegamento, reinserire i tuoi dati.
              Inoltre, se hai aggiunto prodotti al carrello e chiuso la sessione senza completare l&apos;acquisto e
              senza eliminarli, ti consentono di rivedere il tuo carrello (per un tempo determinato) con i
              prodotti scelti precedentemente.</p>
            <p>I cookies funzionali non sono indispensabili per il funzionamento del Sito ma ne migliorano la
              facilità e la velocità di utilizzo.</p>
            <p><strong>Cookie analitici</strong></p>
            <p>Questi cookies permettono l&apos;analisi statistica delle navigazioni degli utenti nel Sito, al solo fine
              di comprenderne l&apos;utilizzo da parte degli utenti. Con questi cookies si tracciano, ad esempio, il
              numero di <em>click</em> effettuati su una pagina del Sito ed il numero di pagine visitate dagli
              utenti.</p>
            <p>Le informazioni raccolte da questi cookies sono trattate in forma aggregata ed anonima e non possono
              tracciare il comportamento dell&apos;utente su altri siti <em>web</em>.</p>
            <p>Questi cookies non sono indispensabili al funzionamento del Sito.</p>
            <p>Il Sito utilizza <em>Google Analytics</em>, un servizio di analisi <em>web</em> fornito da
              <em>Google</em>, che utilizza dei cookies propri. Per maggiori informazioni sui cookies di
              <em>Google Analytics</em>, visitare https://www.google.it/intl/it/policies/privacy/.</p>
            <p><strong>Cookie di Social Network</strong></p>
            <p>Questi cookies sono applicazioni informatiche di terzi che consentono di condividere i contenuti del
              Sito con altre persone, anche attraverso i pulsanti e/o plugin quali “<em>Condividi</em>” e “<em>Mi
                piace</em>”, forniti dai principali <em>social network</em> (es. <em>Facebook</em>,
              <em>Twitter</em>, <em>Google+</em>).</p>
            <p>I cookies di social network non sono necessari alla navigazione.</p>
            <p><strong>Come posso disabilitare i cookies?</strong></p>
            <p>La maggior parte dei <em>browser</em> accettano i cookies automaticamente, ma puoi anche scegliere di
              non accettarli. Si consiglia di non disabilitare questa funzione, dal momento che ciò potrebbe
              impedire di muoversi liberamente da una pagina all&apos;altra e di fruire di tutte le peculiarità del
              Sito.</p>
            <p>Se non desideri che il tuo computer riceva e memorizzi cookies, puoi modificare le impostazioni di
              sicurezza del tuo <em>browser</em> (Internet Explorer, Google Chrome, Safari etc.). In ogni caso, si
              deve notare che determinate parti del Sito possono essere utilizzate nella loro pienezza solo se il
              <em>browser</em> accetta i cookies; in caso contrario, non sarai in grado, ad esempio, di aggiungere
              prodotti al tuo carrello ed acquistarli. Di conseguenza, la tua scelta di rimuovere e non accettare
              cookies potrà influenzare negativamente la tua permanenza sul nostro Sito.</p>
            <p>Qui di seguito trovi delle brevi istruzioni per gestire i cookies con i principali <em>browser</em>.
            </p>
            <p><strong>Microsoft Internet Explorer</strong></p>
            <p>Cliccare l&apos;icona “Strumenti” nell&apos;angolo in alto a destra e selezionare “Opzioni internet”. Nella
              finestra di <em>pop-up</em> selezionare “Privacy”. Qui potrai regolare le impostazioni dei tuoi
              cookies.</p>
            <p><strong>Google Chrome </strong></p>
            <p>Cliccare la chiave inglese nell&apos;angolo in alto a destra e selezionare “Impostazioni”. A questo punto
              selezionare “Mostra impostazioni avanzate” e cambiare le impostazioni della “Privacy”.</p>
            <p><strong>Mozilla Firefox</strong></p>
            <p>Dal menu a tendina nell&apos;angolo in alto a sinistra selezionare “Opzioni”. Nella finestra di
              <em>pop-up</em> selezionare “Privacy”. Qui puoi regolare le impostazioni dei tuoi cookies.</p>
            <p><strong>Safari</strong></p>
            <p>Dal menu di impostazione a tendina nell&apos;angolo in alto a destra selezionare “Preferenze”. Selezionare
              “Sicurezza” e qui potrai regolare le impostazioni dei tuoi cookies.</p>
            <p>Per disabilitare i cookie analitici e per impedire a <em>Google Analytics</em> di raccogliere dati
              sulla tua navigazione, puoi scaricare il Componente aggiuntivo del browser per la disattivazione di
              <em>Google Analytics</em>: <a
                href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
            </p>
            <p><strong>Altre tipologie di Cookie o strumenti terzi che potrebbero installarne</strong></p>
            <p>Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e
              potrebbero non richiedere il consenso dell&apos;utente o potrebbero essere gestiti direttamente dal
              Titolare - a seconda di quanto descritto - senza l&apos;ausilio di terzi.</p>
            <p>Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi
              potrebbero - in aggiunta a quanto specificato ed anche all&apos;insaputa del Titolare - compiere attività
              di tracciamento dell&apos;utente. Per informazioni dettagliate in merito, si consiglia di consultare le
              privacy policy dei servizi elencati.</p>
            <div className="one_line_col">
              <h3 id="technical_cookies">Cookie tecnici e di statistica aggregata</h3>
              <ul className="for_boxes">
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 icon_general">Attività strettamente necessarie al
                      funzionamento</h3>
                    <div className="expand-content">
                      <p>Questa Applicazione utilizza Cookie per salvare la sessione dell&apos;Utente e per
                        svolgere altre attività strettamente necessarie al funzionamento di questa
                        Applicazione, ad esempio in relazione alla distribuzione del traffico.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 icon_general">Attività di salvataggio delle
                      preferenze, ottimizzazione e statistica</h3>
                    <div className="expand-content">
                      <p>Questa Applicazione utilizza Cookie per salvare le preferenze di navigazione ed
                        ottimizzare l&apos;esperienza di navigazione dell&apos;Utente. Fra questi Cookie
                        rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la
                        gestione di statistiche da parte del Titolare del sito.</p>
                    </div>
                  </div>
                </li>
              </ul>
              <h3 id="other_types_cookies">Altre tipologie di Cookie o strumenti terzi che potrebbero installarne
              </h3>
              <p>Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e
                potrebbero non richiedere il consenso dell&apos;Utente o potrebbero essere gestiti direttamente dal
                Titolare - a seconda di quanto descritto - senza l&apos;ausilio di terzi.</p>
              <p>Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi
                potrebbero - in aggiunta a quanto specificato ed anche all&apos;insaputa del Titolare - compiere
                attività di tracciamento dell&apos;Utente. Per informazioni dettagliate in merito, si consiglia di
                consultare le privacy policy dei servizi elencati.</p>
              <ul className="for_boxes">
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773601">Commento dei contenuti
                    </h3>
                    <div className="expand-content">
                      <p>I servizi di commento consentono agli Utenti di formulare e rendere pubblici
                        propri commenti riguardanti il contenuto di questa Applicazione.<br /> Gli Utenti,
                        a seconda delle impostazioni decise dal Titolare, possono lasciare il commento
                        anche in forma anonima. Nel caso tra i Dati Personali rilasciati dall&apos;Utente ci
                        sia l&apos;email, questa potrebbe essere utilizzata per inviare notifiche di commenti
                        riguardanti lo stesso contenuto. Gli Utenti sono responsabili del contenuto dei
                        propri commenti.<br /> Nel caso in cui sia installato un servizio di commenti
                        fornito da soggetti terzi, è possibile che, anche nel caso in cui gli Utenti non
                        utilizzino il servizio di commento, lo stesso raccolga dati di traffico relativi
                        alle pagine in cui il servizio di commento è installato.</p>
                      <h4>Facebook Comments (Facebook, Inc.)</h4>
                      <div className="wrap">
                        <p>Facebook Comments è un servizio gestito da Facebook, Inc. che consente
                          all&apos;Utente di lasciare propri commenti e condividerli all&apos;interno della
                          piattaforma Facebook.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://www.facebook.com/privacy/explanation" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773579">Contattare l&apos;Utente</h3>
                    <div className="expand-content">
                      <h4>Mailing list o newsletter (questa Applicazione)</h4>
                      <div className="wrap">
                        <p>Con la registrazione alla mailing list o alla newsletter, l&apos;indirizzo email
                          dell&apos;Utente viene automaticamente inserito in una lista di contatti a cui
                          potranno essere trasmessi messaggi email contenenti informazioni, anche di
                          natura commerciale e promozionale, relative a questa Applicazione.
                          L&apos;indirizzo email dell&apos;Utente potrebbe anche essere aggiunto a questa lista
                          come risultato della registrazione a questa Applicazione o dopo aver
                          effettuato un acquisto.</p>
                      </div>
                      <p>Dati Personali raccolti: CAP, città, cognome, Cookie, data di nascita, Dati di
                        utilizzo, email, indirizzo, nazione e nome.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773632">Gestione contatti e invio
                      di messaggi</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi consente di gestire un database di contatti email,
                        contatti telefonici o contatti di qualunque altro tipo, utilizzati per
                        comunicare con l&apos;Utente.<br /> Questi servizi potrebbero inoltre consentire di
                        raccogliere dati relativi alla data e all&apos;ora di visualizzazione dei messaggi da
                        parte dell&apos;Utente, così come all&apos;interazione dell&apos;Utente con essi, come le
                        informazioni sui click sui collegamenti inseriti nei messaggi.</p>
                      <h4>MailUp (MailUp)</h4>
                      <div className="wrap">
                        <p>MailUp è un servizio di gestione indirizzi e invio di messaggi email fornito
                          da MailUp S.p.A.</p>
                      </div>
                      <p>Dati Personali raccolti: cognome, Cookie, data di nascita, Dati di utilizzo,
                        email, indirizzo, nazione, nome, numero di telefono, professione, ragione
                        sociale, sesso, username e varie tipologie di Dati.</p>
                      <p>Luogo del trattamento: Italia -&nbsp;<a
                        href="https://www.mailup.it/informativa-privacy/mailup-spa/" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773616">Gestione dei tag</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o script
                        utilizzati su questa Applicazione.<br /> L&apos;uso di tali servizi comporta il fluire
                        dei Dati dell&apos;Utente attraverso gli stessi e, se del caso, la loro ritenzione.
                      </p>
                      <h4>Google Tag Manager (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google Tag Manager è un servizio di gestione dei tag fornito da Google LLC.
                        </p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773582">Interazione con social
                      network e piattaforme esterne</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi permette di effettuare interazioni con i social network, o
                        con altre piattaforme esterne, direttamente dalle pagine di questa
                        Applicazione.<br /> Le interazioni e le informazioni acquisite da questa
                        Applicazione sono in ogni caso soggette alle impostazioni privacy dell&apos;Utente
                        relative ad ogni social network.<br /> Nel caso in cui sia installato un servizio
                        di interazione con i social network, è possibile che, anche nel caso gli Utenti
                        non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle
                        pagine in cui è installato.</p>
                      <h4>Pulsante e widget sociali di Linkedin (LinkedIn Corporation)</h4>
                      <div className="wrap">
                        <p>Il pulsante e i widget sociali di LinkedIn sono servizi di interazione con il
                          social network Linkedin, forniti da LinkedIn Corporation.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                      <h4>Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.)</h4>
                      <div className="wrap">
                        <p>Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di
                          interazione con il social network Facebook, forniti da Facebook, Inc.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://www.facebook.com/privacy/explanation" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                      <h4>Pulsante Tweet e widget sociali di Twitter (Twitter, Inc.)</h4>
                      <div className="wrap">
                        <p>Il pulsante Tweet e i widget sociali di Twitter sono servizi di interazione
                          con il social network Twitter, forniti da Twitter, Inc.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a href="https://twitter.com/privacy"
                        target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Soggetto
                        aderente al Privacy Shield.</p>
                      <h4>Pulsante +1 e widget sociali di Google+ (Google LLC)</h4>
                      <div className="wrap">
                        <p>Il pulsante +1 e i widget sociali di Google+ sono servizi di interazione con
                          il social network Google+, forniti da Google LLC.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                      <h4>Pulsante e widget di PayPal (Paypal)</h4>
                      <div className="wrap">
                        <p>Il pulsante e widget di PayPal sono servizi di interazione con la piattaforma
                          PayPal, forniti da PayPal Inc.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Consulta la privacy policy di Paypal -&nbsp;<a
                        href="https://www.paypal.com/cgi-bin/webscr?cmd=p/gen/ua/policy_privacy-outside"
                        target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                      <h4>Pulsante follow e widget sociali di AngelList (AngelList, LLC.)</h4>
                      <div className="wrap">
                        <p>Il pulsante follow ed i widget sociali di AngelList sono servizi di
                          interazione con il network AngelList, forniti da AngelList, LLC.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a href="https://angel.co/privacy"
                        target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                      <h4>Pulsante “Pin it” e widget sociali di Pinterest (Pinterest)</h4>
                      <div className="wrap">
                        <p>Il pulsante “Pin it” e widget sociali di Pinterest sono servizi di
                          interazione con la piattaforma Pinterest, forniti da Pinterest Inc.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://about.pinterest.com/en/privacy-policy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>.</p>
                      <h4>Shareaholic (Shareaholic, Inc.)</h4>
                      <div className="wrap">
                        <p>Shareaholic è un servizio fornito da Shareaholic, Inc. che visualizza un
                          widget che permette l&apos;interazione con social network e piattaforme esterne e
                          la condivisione dei contenuti di questa Applicazione.<br /> A seconda della
                          configurazione, questo servizio può mostrare widget appartenenti a terze
                          parti, ad esempio i gestori dei social network su cui condividere le
                          interazioni. In questo caso, anche le terze parti che erogano il widget
                          verranno a conoscenza delle interazione effettuata e dei Dati di Utilizzo
                          relativi alle pagine in cui questo servizio è installato.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://shareaholic.com/privacy/" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>&nbsp;-&nbsp;<a
                          href="https://shareaholic.com/privacy/choices">Opt Out</a>.</p>
                      <h4>ShareThis (Sharethis Inc.)</h4>
                      <div className="wrap">
                        <p>ShareThis è un servizio fornito da ShareThis Inc che visualizza un widget che
                          permette l&apos;interazione con social network e piattaforme esterne e la
                          condivisione dei contenuti di questa Applicazione.<br /> A seconda della
                          configurazione, questo servizio può mostrare widget appartenenti a terze
                          parti, ad esempio i gestori dei social network su cui condividere le
                          interazioni. In questo caso, anche le terze parti che erogano il widget
                          verranno a conoscenza delle interazione effettuata e dei Dati di Utilizzo
                          relativi alle pagine in cui questo servizio è installato.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a href="http://sharethis.com/privacy"
                        target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773645">Monitoraggio
                      dell&apos;infrastruttura</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi permette a questa Applicazione di monitorare l&apos;utilizzo ed
                        il comportamento di componenti della stessa, per consentirne il miglioramento
                        delle prestazioni e delle funzionalità, la manutenzione o la risoluzione di
                        problemi.<br /> I Dati Personali trattati dipendono dalle caratteristiche e della
                        modalità d&apos;implementazione di questi servizi, che per loro natura filtrano
                        l&apos;attività di questa Applicazione.</p>
                      <h4>Web Performance (Web Performance, Inc.)</h4>
                      <div className="wrap">
                        <p>Web Performance è un servizio di monitoraggio fornito da Web Performance,
                          Inc.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://www.webperformance.com/company/terms-of-use.html"
                        target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773598">Ottimizzazione e
                      distribuzione del traffico</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi permette a questa Applicazione di distribuire i propri
                        contenuti tramite dei server dislocati sul territorio e di ottimizzare le
                        prestazioni della stessa.<br /> I Dati Personali trattati dipendono dalle
                        caratteristiche e della modalità d&apos;implementazione di questi servizi, che per
                        loro natura filtrano le comunicazioni fra questa Applicazione ed il browser
                        dell&apos;Utente.<br /> Vista la natura distribuita di questo sistema, è difficile
                        determinare i luoghi in cui vengono trasferiti i contenuti, che potrebbero
                        contenere Dati Personali dell&apos;Utente.</p>
                      <h4>Cloudflare (Cloudflare Inc.)</h4>
                      <div className="wrap">
                        <p>Cloudflare è un servizio di ottimizzazione e distribuzione del traffico
                          fornito da Cloudflare Inc.<br /> Le modalità di integrazione di Cloudflare
                          prevedono che questo filtri tutto il traffico di questa Applicazione, ossia
                          le comunicazioni fra questa Applicazione ed il browser dell&apos;Utente,
                          permettendo anche la raccolta di dati statistici su di esso.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e varie tipologie di Dati secondo quanto
                        specificato dalla privacy policy del servizio.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://www.cloudflare.com/security-policy/" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773615">Protezione dallo SPAM</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi analizza il traffico di questa Applicazione,
                        potenzialmente contenente Dati Personali degli Utenti, al fine di filtrarlo da
                        parti di traffico, messaggi e contenuti riconosciuti come SPAM.</p>
                      <h4>Google reCAPTCHA (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google reCAPTCHA è un servizio di protezione dallo SPAM fornito da Google
                          LLC.<br /> L&apos;utilizzo del sistema reCAPTCHA è soggetto alla&nbsp;<a
                            href="https://www.google.com/intl/it/policies/privacy/">privacy
                            policy</a>&nbsp;e ai&nbsp;<a
                              href="https://www.google.com/intl/it/policies/terms/">termini di
                            utilizzo</a>&nbsp;di Google.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773591">Registrazione ed
                      autenticazione</h3>
                    <div className="expand-content">
                      <p>Con la registrazione o l&apos;autenticazione l&apos;Utente consente all&apos;Applicazione di
                        identificarlo e di dargli accesso a servizi dedicati.<br /> A seconda di quanto
                        indicato di seguito, i servizi di registrazione e di autenticazione potrebbero
                        essere forniti con l&apos;ausilio di terze parti. Qualora questo avvenga, questa
                        applicazione potrà accedere ad alcuni Dati conservati dal servizio terzo usato
                        per la registrazione o l&apos;identificazione.</p>
                      <h4>Auth0 (Auth0, Inc)</h4>
                      <div className="wrap">
                        <p>Auth0 è un servizio di registrazione ed autenticazione fornito da Auth0, Inc.
                          Per semplificare il processo di registrazione ed autenticazione, Auth0 può
                          utilizzare fornitori di identità di terze parti e salvare le informazioni
                          sulla propria piattaforma.</p>
                      </div>
                      <p>Dati Personali raccolti: cognome, Cookie, email, immagine, nome, password e varie
                        tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.
                      </p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a href="https://auth0.com/privacy"
                        target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773610">Remarketing e behavioral
                      targeting</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi consente a questa Applicazione ed ai suoi partner di
                        comunicare, ottimizzare e servire annunci pubblicitari basati sull&apos;utilizzo
                        passato di questa Applicazione da parte dell&apos;Utente.<br /> Questa attività viene
                        effettuata tramite il tracciamento dei Dati di Utilizzo e l&apos;uso di Cookie,
                        informazioni che vengono trasferite ai partner a cui l&apos;attività di remarketing e
                        behavioral targeting è collegata.<br /> Alcuni servizi offrono un&apos;opzione di
                        remarketing basata sulle liste di indirizzi email.<br /> In aggiunta alle
                        possibilità di effettuare l&apos;opt-out offerte dai servizi di seguito riportati,
                        l&apos;Utente può optare per l&apos;esclusione rispetto alla ricezione dei cookie relativi
                        ad un servizio terzo, visitando la&nbsp;<a
                          href="http://optout.networkadvertising.org/">pagina di opt-out del Network
                          Advertising Initiative</a>.</p>
                      <h4>Google Ad Manager Audience Extension (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google Ad Manager Audience Extension è un servizio di remarketing e
                          behavioral targeting fornito da Google LLC che traccia i visitatori di
                          questa Applicazione e consente a partner pubblicitari selezionati di
                          mostrare ad essi annunci personalizzati all&apos;interno del web.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>&nbsp;-&nbsp;<a
                          href="https://www.google.com/settings/ads/onweb#display_optout">Opt Out</a>.
                        Soggetto aderente al Privacy Shield.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773576">Statistica</h3>
                    <div className="expand-content">
                      <p>I servizi contenuti nella presente sezione permettono al Titolare del Trattamento
                        di monitorare e analizzare i dati di traffico e servono a tener traccia del
                        comportamento dell&apos;Utente.</p>
                      <h4>Google Analytics (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google Analytics è un servizio di analisi web fornito da Google LLC
                          (“Google”). Google utilizza i Dati Personali raccolti allo scopo di
                          tracciare ed esaminare l&apos;utilizzo di questa Applicazione, compilare report e
                          condividerli con gli altri servizi sviluppati da Google.<br /> Google potrebbe
                          utilizzare i Dati Personali per contestualizzare e personalizzare gli
                          annunci del proprio network pubblicitario.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>&nbsp;-&nbsp;<a
                          href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank"
                          rel="noopener noreferrer">Opt Out</a>. Soggetto aderente al Privacy Shield.
                      </p>
                      <h4>Google Analytics con IP anonimizzato (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google Analytics è un servizio di analisi web fornito da Google LLC
                          (“Google”). Google utilizza i Dati Personali raccolti allo scopo di
                          tracciare ed esaminare l&apos;utilizzo di questa Applicazione, compilare report e
                          condividerli con gli altri servizi sviluppati da Google.<br /> Google potrebbe
                          utilizzare i Dati Personali per contestualizzare e personalizzare gli
                          annunci del proprio network pubblicitario.<br /> Questa integrazione di Google
                          Analytics rende anonimo il tuo indirizzo IP. L&apos;anonimizzazione funziona
                          abbreviando entro i confini degli stati membri dell&apos;Unione Europea o in
                          altri Paesi aderenti all&apos;accordo sullo Spazio Economico Europeo l&apos;indirizzo
                          IP degli Utenti. Solo in casi eccezionali, l&apos;indirizzo IP sarà inviato ai
                          server di Google ed abbreviato all&apos;interno degli Stati Uniti.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>&nbsp;-&nbsp;<a
                          href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank"
                          rel="noopener noreferrer">Opt Out</a>. Soggetto aderente al Privacy Shield.
                      </p>
                      <h4>Web-Stat live (Web Tracking Services, LLC.)</h4>
                      <div className="wrap">
                        <p>Web-Stat live è un servizio di statistica fornito da Web Tracking Services,
                          LLC.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://www.web-stat.com/help_privacy_policy.htm" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773619">Test di performance di
                      contenuti e funzionalità (A/B testing)</h3>
                    <div className="expand-content">
                      <p>I servizi contenuti in questa sezione permettono al Titolare del Trattamento di
                        tener traccia ed analizzare la risposta da parte dell&apos;Utente, in termini di
                        traffico o di comportamento, in relazione a modifiche della struttura, del testo
                        o di qualunque altro componente di questa Applicazione.</p>
                      <h4>Google Website Optimizer (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google Website Optimizer è un servizio di A/B testing fornito da Google LLC.
                          (“Google”).<br /> Google potrebbe utilizzare i Dati Personali per
                          contestualizzare e personalizzare gli annunci del suo network pubblicitario.
                        </p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="box_primary box_10 expand">
                    <h3 className="expand-click w_icon_24 policyicon_purpose_5773588">Visualizzazione di
                      contenuti da piattaforme esterne</h3>
                    <div className="expand-content">
                      <p>Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme
                        esterne direttamente dalle pagine di questa Applicazione e di interagire con
                        essi.<br /> Nel caso in cui sia installato un servizio di questo tipo, è possibile
                        che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga
                        dati di traffico relativi alle pagine in cui è installato.</p>
                      <h4>Google Site Search (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google Site Search è un servizio di incorporamento di motori di ricerca
                          gestito da Google LLC che permette a questa Applicazione di integrare tali
                          contenuti all&apos;interno delle proprie pagine.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                      <h4>Widget Google Maps (Google LLC)</h4>
                      <div className="wrap">
                        <p>Google Maps è un servizio di visualizzazione di mappe gestito da Google LLC
                          che permette a questa Applicazione di integrare tali contenuti all&apos;interno
                          delle proprie pagine.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                      <h4>Widget Video YouTube (Google LLC)</h4>
                      <div className="wrap">
                        <p>YouTube è un servizio di visualizzazione di contenuti video gestito da Google
                          LLC che permette a questa Applicazione di integrare tali contenuti
                          all&apos;interno delle proprie pagine.</p>
                      </div>
                      <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
                      <p>Luogo del trattamento: Stati Uniti -&nbsp;<a
                        href="https://policies.google.com/privacy" target="_blank"
                        rel="noopener noreferrer">Privacy Policy</a>. Soggetto aderente al Privacy
                        Shield.</p>
                    </div>
                  </div>
                </li>
              </ul>
              <h3 id="owner_of_the_data">Titolare del Trattamento dei Dati</h3>
              <p>2050 srl<br /> Via Faà di Bruno 2<br /> 10153 Torino<br /> Cf e p.i. 12001190011</p>
              <p><b>Indirizzo email del Titolare:</b>&nbsp;info@eugenioinviadigioia.it</p>
              <p>Vista l&apos;oggettiva complessità di identificazione delle tecnologie basate sui cookie l&apos;utente è
                invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo
                all&apos;utilizzo dei cookie stessi.</p>
            </div>
            <div className="box_primary box_10 definitions expand">
              <p><em>Versione 1.0 del 5 marzo 2019</em></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;