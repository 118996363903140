import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import clsx from 'clsx';

import CreditGif from '../../images/loaders/credits.gif';
import CreditGifBlack from '../../images/loaders/credits_black.gif';
import LogoInfoPng from '../../images/gifs/logo-info.png';
import LogoInfoPngBlack from '../../images/gifs/black/logo-info_black.png';

import styles from './InfoModal.module.scss';

const InfoModal = ({ isOpen = true, onClose, lightMode, showThankYou = false, showLogoInfo = false }) => {
  const [gifLoaded, setGifLoaded] = useState(false);
  const [credits, setCredits] = useState(false);

  const isDesktop = window.innerWidth > 1024;

  const [creditsAnimation, creditsAnimationApi] = useSpring(() => ({
    from: {
      opacity: 0,
      X: '100px',
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const [animationStyles, animationApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const [contentAnimationStyles, contentAnimationApi] = useSpring(() => ({
    from: {
      opacity: 0,
      y: '100px'
    },
    config: {
      friction: 21,
      tension: 200,
      mass: 2,
    }
  }));

  const animateCredits = () => {
    setCredits(true);
  };

  useEffect(() => {
    if (!showThankYou && !showLogoInfo) {
      creditsAnimationApi.start({
        from: {
          x: '100px'
        },
        to: async (next) => ([
          await next({
            x: '0',
            opacity: credits ? 1 : 0,
          }),
        ]),
      });
    }
  }, [credits, showThankYou, showLogoInfo]);

  useEffect(() => {
    animationApi.start({
      to: {
        opacity: 0.94,
      },
    });

    contentAnimationApi.start({
      to: {
        opacity: 1,
        y: '0'
      },
    });

    setTimeout(() => {
      animateCredits();
    }, 3000);
  }, []);

  useEffect(() => {
    if (!showLogoInfo && !showThankYou) {
      setTimeout(() => {
        setGifLoaded(true);
      }, 3000);
    }
    // } else if (showThankYou) {
    //   setTimeout(() => {
    //     setGifLoaded(true);
    //   }, 2000);
    // } else {
    //   setTimeout(() => {
    //     setGifLoaded(true);
    //   }, 1000);
    // }
  }, [showLogoInfo, showThankYou]);

  const mainClass = clsx({
    [styles.main]: true,
    [styles['main--open']]: isOpen,
    [styles['main--lightMode']]: lightMode,
  });

  const closeButtonClass = clsx({
    [styles.button]: true,
    [styles['button--close']]: true,
  });

  const visibleImageClass = clsx({
    [styles.image]: true,
    [styles['image--show']]: true,
  });

  const contentClass = (type) => clsx({
    [styles.content]: true,
    [styles[`content--${type}`]]: true,
  });

  return (
    <div className={mainClass}>
      <animated.div className={styles.overlay} style={animationStyles} />
      {isDesktop && !showThankYou && !showLogoInfo && (
        <animated.div className={styles.credits} style={creditsAnimation} onClick={() => {
          window.dataLayer.push({ event: 'DaduxioNico' });
        }}>
          <a href='https://www.instagram.com/daduxio/' target='_blank' rel="noreferrer">
            <img src={lightMode ? CreditGifBlack : CreditGif} alt="Made with love by daduxio and nico" />
          </a>
        </animated.div>
      )}
      <div
        role='button'
        onClick={onClose}
        className={closeButtonClass}
      />
      {showThankYou && (
        <animated.div className={contentClass('thank-you')} style={contentAnimationStyles}>
          <img className={visibleImageClass} src={lightMode ? 'https://www.daduxio.it/d4dux10/img/thank-you_black.png' : 'https://www.daduxio.it/d4dux10/img/thank-you.png'} alt="info-png" />
        </animated.div>
      )}
      {showLogoInfo && !showThankYou && (
        <animated.div className={contentClass('logo-info')} style={contentAnimationStyles}>
          <img className={visibleImageClass} src={lightMode ? LogoInfoPngBlack : LogoInfoPng} alt="info-png" />
        </animated.div>
      )}
      {!showThankYou && !showLogoInfo && (
        <animated.div className={contentClass('')} style={contentAnimationStyles}>
          <img className={`${gifLoaded ? styles.image : visibleImageClass}`} src={lightMode ? 'https://www.daduxio.it/d4dux10/img/info_video_black.gif' : 'https://www.daduxio.it/d4dux10/img/info_video.gif'} alt="info-gif" />
          <img className={`${gifLoaded ? visibleImageClass : styles.image}`} src={lightMode ? 'https://www.daduxio.it/d4dux10/img/info_video_black.png' : 'https://www.daduxio.it/d4dux10/img/info_video.png'} alt="info-png" />
        </animated.div>
      )}
      {!isDesktop && !showThankYou && !showLogoInfo && (
        <animated.div className={styles.credits} style={creditsAnimation} onClick={() => {
          window.dataLayer.push({ event: 'DaduxioNico' });
        }}>
          <a href='https://www.instagram.com/daduxio/' target='_blank' rel="noreferrer">
            <img src={lightMode ? CreditGifBlack : CreditGif} alt="Made with love by daduxio and nico" />
          </a>
        </animated.div>
      )}
    </div>
  );
};

InfoModal.propTypes = {
  isOpen: PropTypes.bool,
  lightMode: PropTypes.bool.isRequired,
  showThankYou: PropTypes.bool,
  showLogoInfo: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default InfoModal;
